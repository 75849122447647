<script>

export default {
  name: "CapeDataset",
  props: {dataset: {type: Object, default: null}},
  template: "#templateDataset"
};

</script>


